import React, { useContext, useMemo, useState } from 'react';
import SnackbarContext from '../contexts/SnackbarContext';
import IntentsContext from '../contexts/IntentsContext';
import PendingContext from '../contexts/PendingContext';
import api from '../apis/IntentsApi';

const PendingDataHolder = (props) => {
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const { pendingSite, triggerUpdate, dispatchIntent } = useContext(IntentsContext);

  const [loading, setLoading] = useState(false);
  const [phrases, setPendingPhrases] = useState([]);

  const loadPhrases = () => {
    if (!pendingSite) return;
    setLoading(true);
    dispatchIntent({ type: 'stopUpdate' });
    api.getPendingPhrases(props.project)
      .then((data) => {
        setLoading(false);
        setPendingPhrases(data.phrases || []);
      })
      .catch((err) => {
        setLoading(false);
        setPendingPhrases([]);
        dispatchSnackbar({ style: 'error', text: err.message });
      });
  };

  useMemo(loadPhrases, [pendingSite, triggerUpdate]);

  const penData = { phrases, loading };

  return (
    <>
      <PendingContext.Provider value={penData}>
        {props.children}
      </PendingContext.Provider>
    </>
  );
};

export default PendingDataHolder;
