import { api, escalateError, getResponseData } from './index';

const defaultAudioApiCall = async (endpoint, audioPath, engine) => {
  if (typeof audioPath === 'undefined' || audioPath === null || audioPath.length <= 0) {
    throw new Error('Ruta del audio inválida');
  }

  return api.post(endpoint,
    { filePath: audioPath.toString().trim(), engine: engine.toString().trim().toLowerCase() })
    .then(getResponseData)
    .catch(escalateError);
};

export default class AudiosApi {
  static saveAudio(file) {
    if (typeof file === 'undefined' || file === null) {
      throw new Error('No se ha seleccionado ningún archivo');
    }

    const formData = new FormData();
    formData.append('file', file);

    // noinspection JSUnresolvedVariable
    const config = {
      headers: {
        // eslint-disable-next-line no-underscore-dangle
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
    // noinspection JSCheckFunctionSignatures
    return api.post('/api/audios', formData, config)
      .then(getResponseData)
      .catch(escalateError);
  }

  static async checkVoice(audioPath, engine) {
    return defaultAudioApiCall('/api/audios/checkVoice', audioPath, engine);
  }

  static async getIntervals(audioPath, engine) {
    return defaultAudioApiCall('/api/audios/intervals', audioPath, engine);
  }

  static async getIntent(audioPath, engine) {
    return defaultAudioApiCall('/api/audios/intent', audioPath, engine);
  }

  static async stt(audioPath, engine) {
    return defaultAudioApiCall('/api/audios/stt', audioPath, engine);
  }
}
