import React, { useContext } from 'react';
import { Progress, Table } from 'react-bulma-components';
import ParseContext from '../../contexts/ParseContext';
import Spinner from '../spinner/Spinner';
import './results.css';

const ParserResults = () => {
  const { parsedData: data, loading } = useContext(ParseContext);
  const intentsRecognized = data ? data.intent_ranking || [] : [];
  const entitiesRecognized = data ? data.entities || [] : [];

  const colorForValue = (value) => {
    if (value) {
      if (value > 70) return 'success';
      if (value > 40) return 'warning';
      return 'danger';
    }
    return 'black';
  };

  const valueToText = value => `${value.toString()}%`;

  const renderLoading = () => {
    if (loading) {
      return (<><br/><Spinner className={'is-parsed'} text={'Procesando…'} show={loading}/></>);
    }
    if (data && intentsRecognized <= 0) return (<p>No se asignaron intents para el mensaje</p>);
    return (<></>);
  };

  if (intentsRecognized.length > 0) {
    return (
      <>
        {renderLoading()}
        <h5><b>Resultados</b></h5>
        <br/>
        <Table className={'is-fullwidth is-bordered is-striped is-parsed'} size={'narrow'}>
          <thead>
            <tr>
              <th>Intent</th>
              <th>Nivel de Confianza</th>
              <th>Entities</th>
            </tr>
          </thead>
          <tbody>
            {intentsRecognized.map((it, i) => {
              const conf = (it.confidence || 0) * 100;
              const actualConf = Math.round(conf * 100) / 100;
              return (
                <tr key={i}>
                  <td>{it.name}</td>
                  <td>
                    {valueToText(actualConf)}
                    <Progress max={100}
                              value={actualConf}
                              color={colorForValue(actualConf)}
                              size="small"/>
                  </td>
                  <td>
                    {i === 0
                      ? <div className={'tags'}>
                        {entitiesRecognized.map((ent, ei) => (
                          <span key={ei} className={'tag is-info'}>
                              {`${ent.entity} :: ${ent.value}`}
                            </span>
                        ))}
                      </div>
                      : <></>}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  }
  return (<>{renderLoading()}</>);
};

export default ParserResults;
