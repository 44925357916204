import React from 'react';
import { Container, Hero } from 'react-bulma-components';
import Toolbar from '../toolbar/Toolbar';
import PendingPhrases from '../content/PendingPhrases';

const Pending = ({ project }) => (
  <>
    <Toolbar project={project}/>
    <Hero className={'is-fullheight'}>
      <Hero.Body>
        <Container>
          <PendingPhrases/>
        </Container>
      </Hero.Body>
    </Hero>
  </>
);

export default Pending;
