import React, { useContext } from 'react';
import IntentsContext from '../../contexts/IntentsContext';
import UpdatePhraseContext from '../../contexts/UpdatePhraseContext';
import MDIconText from '../mdi/MDIconText';
import './fab.css';

const NewPhraseFab = () => {
  const { selectedIntent } = useContext(IntentsContext);
  const { dispatchPhrase } = useContext(UpdatePhraseContext);

  const triggerClick = () => dispatchPhrase({ type: 'toUpdate', intent: selectedIntent || '' });

  return (
    <>
      <button className={'fab'} onClick={triggerClick}>
        <MDIconText iconName={'plus'} text={'Nueva Frase'} bold={true} upperCase={true}/>
      </button>
    </>
  );
};

export default NewPhraseFab;
