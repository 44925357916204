import React, { useContext } from 'react';
import { Table } from 'react-bulma-components';
import EntitiesContext from '../../../contexts/EntitiesContext';
import EntityRow from './EntityRow';

const EntitiesTable = ({ phrase }) => {
  const { entities } = useContext(EntitiesContext);
  const actualEntities = phrase.length <= 0 ? (entities || [])
    : (entities || []).filter(it => phrase.toLowerCase().includes(it.words.toLowerCase()));

  if ((actualEntities || []).length <= 0) return (<></>);

  return (
    <>
      <div className={'table-container'}>
        <Table className={'is-bordered is-striped'} size={'narrow'}>
          <thead>
            <tr>
              <th>Entity</th>
              <th>Palabra(s) Clave</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {(actualEntities || []).map(
              (it, i) => <EntityRow key={i} id={it.key} words={it.words}/>,
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default EntitiesTable;
