import { api, escalateError, getResponseData } from './index';

export default class ParseApi {
  static parse(project, phrase) {
    if (typeof project === 'undefined' || project === null || project.length <= 0) {
      throw new Error('No se ha seleccionado ningun proyecto');
    }
    if (typeof phrase === 'undefined' || phrase === null || phrase.length <= 0) {
      throw new Error('No se ha escrito ninguna frase');
    }
    // noinspection JSCheckFunctionSignatures
    return api.post('/api/parse', { message: phrase, project })
      .then(getResponseData)
      .catch(escalateError);
  }
}
