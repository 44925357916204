/* eslint-disable object-curly-newline,react-hooks/exhaustive-deps,no-nested-ternary */
import React, { useContext, useMemo, useReducer } from 'react';
import IntentsContext from '../contexts/IntentsContext';
import UpdatePhraseContext from '../contexts/UpdatePhraseContext';
import SnackbarContext from '../contexts/SnackbarContext';
import api from '../apis/PhrasesApi';

const phraseReducer = (data, action) => {
  switch (action.type) {
    case 'toUpdate':
    case 'toDelete':
      return {
        ...data,
        intent: action.intent || '',
        phrase: action.payload || '',
        toUpdate: action.type === 'toUpdate',
        toDelete: action.type === 'toDelete',
        showModal: true,
        runAction: false,
        forPending: action.pending || false,
      };
    case 'hideModal':
      return {
        ...data,
        showModal: false,
        runAction: false,
        phrase: '',
        intent: '',
      };
    case 'confirmPhrase':
      return {
        ...data,
        showModal: true,
        runAction: true,
        phrase: action.payload || data.phrase || '',
        intent: action.intent || data.intent || '',
        forPending: action.pending || false,
      };
    default:
      return data;
  }
};

const UpdatePhraseDataHolder = (props) => {
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const { dispatchIntent } = useContext(IntentsContext);

  const [updateData, dispatchPhrase] = useReducer(phraseReducer,
    {
      intent: '',
      phrase: '',
      toUpdate: false,
      toDelete: false,
      showModal: false,
      runAction: false,
      forPending: false,
    });

  useMemo(() => {
    if (updateData.runAction && updateData.intent.length > 0 && updateData.phrase.length > 0) {
      dispatchPhrase({ type: 'hideModal' });
      if (updateData.toUpdate) {
        api.savePhrase(props.project, updateData.intent, updateData.phrase)
          .then((res) => {
            dispatchPhrase({ type: 'hideModal' });
            dispatchIntent({ type: 'triggerUpdate' });
            const success = res.success || false;
            const existed = res.existed || false;
            dispatchSnackbar({
              style: success ? existed ? 'warn' : '' : 'error',
              payload: success
                ? existed
                  ? 'Frase previamente guardada!'
                  : 'Frase guardada exitosamente'
                : res.error || 'Ocurrió un error al guardar la frase',
              duration: 5000,
            });
          })
          .catch((err) => {
            dispatchPhrase({ type: 'hideModal' });
            dispatchSnackbar({ style: 'error', text: err.message });
          });
      } else if (updateData.toDelete) {
        api.deletePhrase(props.project, updateData.intent, updateData.phrase)
          .then((res) => {
            dispatchPhrase({ type: 'hideModal' });
            dispatchIntent({ type: 'triggerUpdate' });
            const success = res.success || false;
            dispatchSnackbar({
              style: success ? '' : 'error',
              payload: success ? 'Frase eliminada exitosamente'
                : 'Ocurrió un error al eliminar la frase',
            });
          })
          .catch((err) => {
            dispatchPhrase({ type: 'hideModal' });
            dispatchSnackbar({ style: 'error', text: err.message });
          });
      }
    }
  }, [updateData.intent, updateData.phrase, updateData.toUpdate, updateData.toDelete,
    updateData.runAction]);

  const data = {
    intent: updateData.intent,
    phrase: updateData.phrase,
    toUpdate: updateData.toUpdate,
    toDelete: updateData.toDelete,
    showUpdateModal: updateData.showModal && updateData.toUpdate,
    showDeleteModal: updateData.showModal && updateData.toDelete,
    forPending: updateData.forPending || false,
    dispatchPhrase,
  };

  return (
    <>
      <UpdatePhraseContext.Provider value={data}>
        {props.children}
      </UpdatePhraseContext.Provider>
    </>
  );
};

export default UpdatePhraseDataHolder;
