import React, { useContext } from 'react';
import MDIconText from '../mdi/MDIconText';
import './fab.css';
import ProjectsContext from '../../contexts/ProjectsContext';

const NewProjectFab = () => {
  const { dispatchProject } = useContext(ProjectsContext);

  const triggerClick = () => dispatchProject({ type: 'showModal' });

  return (
    <>
      <button className={'fab'} onClick={triggerClick}>
        <MDIconText iconName={'plus'} text={'Nuevo Proyecto'} bold={true} upperCase={true}/>
      </button>
    </>
  );
};

export default NewProjectFab;
