export default class PhraseCommons {
  static formatPhrase(phrase) {
    if (phrase.startsWith('<script')) return '';
    let actualPhrase = phrase;
    while (actualPhrase.includes('(') && actualPhrase.includes(')')) {
      actualPhrase = actualPhrase.substring(0, actualPhrase.indexOf('('))
        + actualPhrase.substring(actualPhrase.indexOf(')') + 1);
    }
    return actualPhrase.replace(/\[/g, '').replace(/]/g, '');
  }

  static formatPhraseForRasa(phrase, entities) {
    if (phrase.startsWith('<script')) return '';
    const validEntities = (entities || []).filter(
      it => (it.key || '').length > 0 && (it.words || '').length > 0 && phrase.includes(it.words),
    );
    if ((validEntities || []).length <= 0) return phrase;
    let actualPhrase = phrase;
    validEntities.forEach((ent) => {
      const ind = actualPhrase.indexOf(ent.words);
      if (ind >= 0) {
        actualPhrase = `${actualPhrase.substring(0, ind)}[${ent.words}](${ent.key})`
          + `${actualPhrase.substring(ind + ent.words.length)}`;
      }
    });
    return actualPhrase;
  }

  static formatPhraseHTML(phrase, entities) {
    if (phrase.startsWith('<script')) return '';
    if ((entities || []).length <= 0) return phrase;
    const actualPhrase = this.formatPhraseForRasa(phrase, entities);
    if (actualPhrase.length > 0) {
      try {
        let newPhrase = actualPhrase.replace(/\[/g, '<span class="tag is-info">')
          .trim();
        newPhrase = newPhrase.replace(/]/g, '</span>').trim();
        newPhrase = newPhrase.replace(/ *\([^)]*\) */g, '').trim();
        return newPhrase;
      } catch (e) {
        console.error(e);
        return actualPhrase;
      }
    }
    return actualPhrase;
  }
}
