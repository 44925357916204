import React from 'react';
import Phrase from './Phrase';
import PhraseButtons from './PhraseButtons';

const PhraseRow = props => (
  <>
    <tr>
      <td><Phrase phrase={props.phrase}/></td>
      <td><PhraseButtons phrase={props.phrase} project={props.project}
                         expectedIntent={props.expectedIntent || ''}
                         canDelete={props.canDelete}/></td>
    </tr>
  </>
);

export default PhraseRow;
