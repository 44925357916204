import React from 'react';
import MetaTags from 'react-meta-tags';

const SiteTitle = ({ title }) => (
  <>
    <MetaTags>
      <title>{title}</title>
    </MetaTags>
  </>
);

export default SiteTitle;
