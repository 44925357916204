import React, { useContext, useEffect, useMemo, useState, } from 'react';
import { Table } from 'react-bulma-components';
import IntentsContext from '../../contexts/IntentsContext';
import IntentsDropdown from '../sites/IntentsDropdown';
import FilterField from '../filter/FilterField';
import PhraseRow from '../phrase/PhraseRow';
import Spinner from '../spinner/Spinner';
import './tables.css';

const IntentPhrases = ({ project }) => {
  const {
    selectedIntent: intent, intentPhrases, loading, dispatchIntent,
  } = useContext(IntentsContext);

  const [filteredPhrases, setFilteredPhrases] = useState(intentPhrases || []);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    dispatchIntent({ type: 'setForPending', payload: false });
    // eslint-disable-next-line
  }, []);

  const updateFilter = () => {
    setFilteredPhrases(
      filterText.length > 0
        ? intentPhrases.filter(it => it.toLowerCase().includes(filterText.toLowerCase()))
        : intentPhrases || [],
    );
  };

  useMemo(() => {
    updateFilter();
    // eslint-disable-next-line
  }, [intentPhrases, filterText]);

  const renderPhrasesTable = () => (
    <>
      {(!intent || intent.length <= 0)
        ? (
          <>
            <h5><b>Por favor, selecciona un intent</b></h5>
            <br/><br/>
          </>)
        : <></>}
      <Table className={'is-bordered is-striped'} size={'narrow'}>
        <thead>
          <tr>
            <th><IntentsDropdown show={true}/></th>
            {(intentPhrases || []).length <= 0 ? <></>
              : (
                <td>
                  <FilterField onChange={e => setFilterText(e.target.value)} text={filterText}/>
                </td>
              )}
          </tr>
        </thead>
        <tbody>
          {(filteredPhrases || []).map(
            (it, i) => <PhraseRow key={i} phrase={it} canDelete={true} project={project}/>,
          )}
        </tbody>
      </Table>
      {(intent && intent.length > 0 && (intentPhrases || []).length <= 0)
        ? <><p>No se encontraron frases para el intent "{intent}"</p></>
        : <></>}
    </>
  );

  if (intent && intent.length > 0) {
    if (loading) return (<><Spinner text={'Cargando…'} show={loading}/></>);
    return renderPhrasesTable();
  }

  return renderPhrasesTable();
};

export default IntentPhrases;
