import React, { useContext } from 'react';
import { Form } from 'react-bulma-components';
import IntentsContext from '../../contexts/IntentsContext';

const IntentsDropdown = (props) => {
  const { intents, selectedIntent, dispatchIntent } = useContext(IntentsContext);

  const dispatchSelectIntent = (e) => {
    dispatchIntent({ type: 'selectIntent', payload: e.target.value || '' });
  };

  if (!props.show) return (<></>);

  return (
    <>
      <Form.Field>
        <Form.Control>
          <Form.Select refs={'intentSelect'}
                       onChange={dispatchSelectIntent}
                       name="intent"
                       value={selectedIntent}>
            <option value="" disabled defaultValue>Selecciona Intent</option>
            {(intents || []).map(opt => (
              <option key={opt.toLowerCase()}
                      value={opt.toLowerCase()}>{opt.toLowerCase()}</option>
            ))};
          </Form.Select>
        </Form.Control>
      </Form.Field>
    </>
  );
};

export default IntentsDropdown;
