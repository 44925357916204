import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProjectsContext from '../../contexts/ProjectsContext';
import IntentsDataHolder from '../../data/IntentsDataHolder';
import UpdatePhraseDataHolder from '../../data/UpdatePhraseDataHolder';
import PendingDataHolder from '../../data/PendingDataHolder';
import EntitiesDataHolder from '../../data/EntitiesDataHolder';
import EditionModal from '../modals/edition/EditionModal';
import SiteTitle from '../SiteTitle';
import Main from '../sites/Main';
import Pending from '../sites/Pending';
import NewPhraseFab from '../fab/NewPhraseFab';
import Testing from '../sites/Testing';

const SelectedProjectSite = ({ match }) => {
  const { projects } = useContext(ProjectsContext);
  const projectNames = (projects || []).map(it => it.name);

  let project = '';
  try {
    project = match.params.project || '';
  } catch (e) {
  }

  if (project.length <= 0 || (projectNames.length > 0 && !projectNames.includes(project))) {
    return (<><Redirect push to={'/'}/></>);
  }

  return (
    <>
      <IntentsDataHolder project={project}>
        <UpdatePhraseDataHolder project={project}>
          <Switch>
            <Route exact path={`/project/${project}/`}>
              <SiteTitle title={'Entrenador RASA'}/>
              <Main project={project}/>
            </Route>
            <Route exact path={`/project/${project}/test`}>
              <SiteTitle title={'Pruebas ~ Entrenador RASA'}/>
              <Testing project={project}/>
            </Route>
            <Route exact path={`/project/${project}/pending`}>
              <SiteTitle title={'Frases Pendientes ~ Entrenador RASA'}/>
              <PendingDataHolder project={project}>
                <Pending project={project}/>
              </PendingDataHolder>
            </Route>
          </Switch>
          <EntitiesDataHolder>
            <NewPhraseFab/>
            <EditionModal/>
          </EntitiesDataHolder>
        </UpdatePhraseDataHolder>
      </IntentsDataHolder>
    </>
  );
};

export default SelectedProjectSite;
