import { api, escalateError, getResponseData } from './index';

export default class IntentsApi {
  static getAllIntents(project) {
    if (typeof project === 'undefined' || project === null || project.length <= 0) {
      throw new Error('No se ha seleccionado ningun proyecto');
    }
    return api.get(`/api/projects/${project}/intents?ui=1`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getIntentPhrases(project, intent) {
    if (typeof project === 'undefined' || project === null || project.length <= 0) {
      throw new Error('No se ha seleccionado ningun proyecto');
    }
    if (typeof intent === 'undefined' || intent === null || intent.length <= 0) {
      throw new Error('No se ha seleccionado un intent');
    }
    return api.get(`/api/projects/${project}/intents/${intent}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getPendingPhrases(project) {
    if (typeof project === 'undefined' || project === null || project.length <= 0) {
      throw new Error('No se ha seleccionado ningun proyecto');
    }
    return api.get(`/api/pending/${project}`)
      .then(getResponseData)
      .catch(escalateError);
  }
}
