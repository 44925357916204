import React, { useContext, useMemo, useRef, useState, } from 'react';
import { Columns, Form, Modal } from 'react-bulma-components';
import SnackbarContext from '../../../contexts/SnackbarContext';
import IntentsContext from '../../../contexts/IntentsContext';
import UpdatePhraseContext from '../../../contexts/UpdatePhraseContext';
import EntitiesContext from '../../../contexts/EntitiesContext';
import EntitiesTable from './EntitiesTable';
import PhraseCommons from '../../phrase/common';
import '../modal.css';
import './edition.css';

const EditionModal = () => {
  const phraseInputRef = useRef(null);

  const { dispatchSnackbar } = useContext(SnackbarContext);
  const { intents } = useContext(IntentsContext);
  const {
    intent, phrase, showUpdateModal, dispatchPhrase: dispatch,
  } = useContext(UpdatePhraseContext);
  const { entities, dispatchEntities } = useContext(EntitiesContext);

  const [loading, setLoading] = useState(false);
  const [newIntent, setNewIntent] = useState(intent || '');
  const [newPhrase, setNewPhrase] = useState(phrase || '');
  const [selectedText, setSelectedText] = useState('');
  const [intentInputVisible, showIntentInput] = useState((intents || []).length <= 0);
  const [mouseDown, setMouseDown] = useState(false);

  const getActualSelection = () => {
    const ele = document.activeElement;
    let eleName = '';
    try {
      eleName = ele.name || '';
    } catch (e) {
    }
    if (eleName.length <= 0) return '';

    try {
      if (phraseInputRef.current.props.name !== eleName) return '';
    } catch (e) {
    }

    let selection = '';
    try {
      const seleStart = ele.selectionStart || -1;
      const seleEnd = ele.selectionEnd || -1;
      if (seleStart < seleEnd) {
        selection = phrase.substring(seleStart, seleEnd);
      }
    } catch (e) {
      selection = '';
    }
    if (window.getSelection && selection.length <= 0) {
      selection = window.getSelection();
    }
    return selection.toString().trim();
  };

  const handleSelectionChange = () => setSelectedText(getActualSelection());

  const clearSelection = () => {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      document.selection.empty();
    }
    setSelectedText('');
  };

  useMemo(() => {
    setLoading(false);
    document.addEventListener('selectionchange', showUpdateModal ? handleSelectionChange : null);
    if (showUpdateModal) {
      setNewIntent(intent || '');
      setNewPhrase(phrase || '');
      showIntentInput((intents || []).length <= 0);
    } else {
      clearSelection();
      setNewIntent('');
      setNewPhrase('');
      showIntentInput(false);
      dispatchEntities({ type: 'clear' });
    }
    // eslint-disable-next-line
  }, [phrase, showUpdateModal, intent]);

  useMemo(() => {
    // Trigger an update when selectedText changes, so the button is rendered properly
    // eslint-disable-next-line
  }, [selectedText]);

  const dispatchHide = () => {
    if (loading) return;
    setNewIntent('');
    setNewPhrase('');
    dispatch({ type: 'hideModal' });
  };

  const dispatchPhrase = () => {
    const emptyEntities = entities.filter(it => (it.key || '').length <= 0);
    if (emptyEntities.length > 0) {
      dispatchSnackbar(
        {
          style: 'warn',
          text: `Hay ${emptyEntities.length} entities sin identificador.`,
        },
      );
      return;
    }
    if (newIntent.length > 0 && newPhrase.length > 0) {
      setLoading(true);
      dispatch({
        type: 'confirmPhrase',
        payload: PhraseCommons.formatPhraseForRasa(newPhrase, entities),
        intent: newIntent,
      });
    } else {
      const message = newIntent.length <= 0 ? 'Selecciona un intent' : 'Escribe una frase';
      dispatchSnackbar({ style: 'warn', text: message });
    }
  };

  const addEntity = () => {
    dispatchEntities({ type: 'add', payload: { key: '', words: selectedText } });
    setSelectedText('');
  };

  const renderSelectionBtn = () => {
    const shouldShow = selectedText.length > 0 && newPhrase.length > 0 && !mouseDown;
    if (!shouldShow) return (<></>);

    const selectionInText = newPhrase.toLowerCase().includes(selectedText.toLowerCase());
    const selectionInEntities = entities
      .filter(it => it.words.toLowerCase() === selectedText.toLowerCase()).length > 0;
    if (selectionInText && !selectionInEntities) {
      return (<>
        <button className={'button is-info'} onClick={addEntity}>
          Añadir entity para "{selectedText}"
        </button>
        <br/><br/>
      </>);
    }
    return (<></>);
  };

  const formatPhrasePreview = () => {
    if (newPhrase.length > 0) {
      return (
        <>
          <p dangerouslySetInnerHTML={{
            __html: PhraseCommons.formatPhraseHTML(newPhrase, entities),
          }}/>
          <br/>
        </>
      );
    }
    return (<></>);
  };

  const checkChanged = () => showIntentInput(!intentInputVisible);

  const cleanIntent = intentInp => (intentInp || '').replace(/[^a-zA-Z0-9-]+/g, '');

  const renderIntentSelector = () => {
    if (intentInputVisible) {
      return (
        <Form.Input
          onChange={e => setNewIntent(e.target.value)}
          name="intent-inp"
          type="text"
          placeholder="affirm"
          value={newIntent}
          autoComplete="off"/>
      );
    }
    return (
      <Form.Select onChange={e => setNewIntent(cleanIntent(e.target.value))}
                   name="intent"
                   value={newIntent}>
        <option value="" disabled defaultValue>Selecciona Intent</option>
        {/* eslint-disable-next-line arrow-body-style */}
        {(intents || []).map((opt) => {
          return (
            <option key={opt.toLowerCase()} value={opt.toLowerCase()}>{opt.toLowerCase()}</option>
          );
        })};
      </Form.Select>
    );
  };

  const mouseNotDown = () => {
    setMouseDown(false);
    if (newPhrase.length <= 0) dispatchEntities({ type: 'clear' });
  };

  if (showUpdateModal) {
    return (<>
      <Modal show={showUpdateModal} closeOnBlur={!loading} showClose={!loading}
             onClose={dispatchHide} onMouseUp={mouseNotDown}>
        <Modal.Content onMouseUp={mouseNotDown}>
          <Modal.Card onMouseUp={mouseNotDown}>
            <Modal.Card.Head showClose={false} onMouseUp={mouseNotDown}>
              <Modal.Card.Title>Entrenar Frase</Modal.Card.Title>
            </Modal.Card.Head>
            <Modal.Card.Body onMouseUp={mouseNotDown}>
              {formatPhrasePreview()}
              <Columns>
                <Columns.Column size={3}>
                  <Form.Field>
                    <Form.Label>Intent</Form.Label>
                    <Form.Control>
                      {renderIntentSelector()}
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Frase</Form.Label>
                    <Form.Control>
                      <Form.Input
                        ref={phraseInputRef}
                        onChange={e => setNewPhrase(e.target.value)}
                        name="phrase"
                        type="text"
                        placeholder="Hola Mundo"
                        value={newPhrase}
                        onKeyUp={mouseNotDown}
                        onBlur={mouseNotDown}
                        onMouseDown={() => setMouseDown(true)}
                        onMouseUp={mouseNotDown}
                        autoComplete="off"/>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
              </Columns>
              <Form.Field>
                <Form.Control>
                  <Form.Checkbox name="showIntentInput"
                                 onChange={checkChanged}
                                 checked={intentInputVisible}>
                    Crear nuevo intent
                  </Form.Checkbox>
                </Form.Control>
              </Form.Field>
              {renderSelectionBtn()}
              <EntitiesTable phrase={newPhrase}/>
            </Modal.Card.Body>
            <Modal.Card.Foot style={{ alignItems: 'right', justifyContent: 'right' }}
                             className={'has-text-right'} onMouseUp={mouseNotDown}>
              <div className={'buttons'}>
                <button className={'button'} onClick={dispatchHide} disabled={loading}>
                  Cancelar
                </button>
                <button className={`button is-info ${loading ? 'is-loading' : ''}`}
                        onClick={dispatchPhrase}>
                  Guardar
                </button>
              </div>
            </Modal.Card.Foot>
          </Modal.Card>
        </Modal.Content>
      </Modal>
    </>);
  }
  return (<></>);
};

export default EditionModal;
