import React, { useState } from 'react';
import { Navbar } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import ToolbarTrainButton from './ToolbarTrainButton';
import MDIcon from '../mdi/MDIcon';
import api from '../../apis/ReportApi';
import './toolbar.css';

const Toolbar = (props) => {
  const [open, setOpen] = useState(false);
  const openMenu = () => setOpen(!open);

  const downloadReport = () => api.downloadReport(props.project);

  return (
    <>
      <nav className={'navbar has-shadow is-fixed-top'}>
        <Navbar.Brand>
          <Link to={'/'} className={'navbar-item'}><MDIcon iconName={'arrow-left'}/></Link>
          <Link to={`/project/${props.project}/`}
                className={'navbar-item'}><b>{props.project}</b></Link>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a role={'button'}
             className={`navbar-burger ${open ? 'is-active' : ''}`}
             href={'#'}
             aria-label={'menu'}
             aria-expanded={false}
             onClick={openMenu}>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
          </a>
        </Navbar.Brand>
        <div className={`navbar-menu ${open ? 'is-active' : ''}`}>
          <Navbar.Container position="end">
            <Link to={`/project/${props.project}/test`} className={'navbar-item'}>Pruebas</Link>
            <Link to={`/project/${props.project}/pending`}
                  className={'navbar-item'}>Pendientes</Link>
            <Navbar.Item onClick={downloadReport}>Reporte</Navbar.Item>
            <Navbar.Item>
              <ToolbarTrainButton/>
            </Navbar.Item>
          </Navbar.Container>
        </div>
      </nav>
    </>
  );
};

export default Toolbar;
