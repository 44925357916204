import React from 'react';
import MDIcon from './MDIcon';

const MDIconText = (props) => {
  const renderText = () => {
    const actualText = props.upperCase ? (props.text || '').toUpperCase() : (props.text || '');
    if (props.bold) return (<b>{actualText}</b>);
    return actualText;
  };

  if (props.textPosition === 'left') {
    return (
      <p>
        {renderText()}&nbsp;&nbsp;<MDIcon iconName={props.iconName || ''} onClick={props.onClick}/>
      </p>
    );
  }
  return (
    <p>
      <MDIcon iconName={props.iconName || ''}/>&nbsp;&nbsp;{renderText()}
    </p>
  );
};

export default MDIconText;
