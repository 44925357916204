import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Box } from 'react-bulma-components';
import MDIcon from '../mdi/MDIcon';


const Project = ({ name }) => {
  const [redirect, setRedirect] = useState(false);

  const onDoubleClick = () => setRedirect(true);

  if (redirect) {
    return (<><Redirect push to={`/project/${name}/`}/></>);
  }

  return (
    <Box className={'has-text-centered project'} onClick={onDoubleClick}>
      <MDIcon iconName={'folder'}/>
      <br/>
      <p><b>{name}</b></p>
    </Box>
  );
};

export default Project;
