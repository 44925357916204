// eslint-disable-next-line object-curly-newline
import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import ProjectsContext from '../contexts/ProjectsContext';
import SnackbarContext from '../contexts/SnackbarContext';
import api from '../apis/ProjectsApi';

const projectsReducer = (data, action) => {
  switch (action.type) {
    case 'showModal':
      return { ...data, showModal: true, runAction: false };
    case 'hideModal':
      return { ...data, showModal: false, runAction: false };
    case 'create':
      return { ...data, runAction: true, newProject: action.payload };
    default:
      return data;
  }
};

const ProjectsDataHolder = (props) => {
  const { dispatchSnackbar } = useContext(SnackbarContext);

  const [projectData, dispatchProject] = useReducer(projectsReducer,
    { showModal: false, runAction: false, newProject: '' });
  
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  const loadProjects = () => {
    setLoading(true);
    api.getProjects()
      .then((data) => {
        setLoading(false);
        setProjects(data.projects || []);
      })
      .catch((err) => {
        setLoading(false);
        dispatchSnackbar({ style: 'error', text: err.message });
      });
  };

  useEffect(() => {
    loadProjects();
    // eslint-disable-next-line
  }, []);

  useMemo(() => {
    if (projectData.runAction) {
      projectData.runAction = false;
      api.saveProject(projectData.newProject)
        .then((data) => {
          projectData.newProject = '';
          const { name: savedName } = data.project || '';
          dispatchProject({ type: 'hideModal' });
          if (savedName.length > 0) {
            dispatchSnackbar({ text: `Proyecto "${savedName}" creado satisfactoriamente` });
            loadProjects();
          } else {
            dispatchSnackbar({ style: 'error', text: 'Error al crear proyecto' });
          }
        })
        .catch((err) => {
          projectData.newProject = '';
          dispatchProject({ type: 'hideModal' });
          dispatchSnackbar({ style: 'error', text: err.message });
        });
    }
    // eslint-disable-next-line
  }, [projectData.runAction]);

  const proData = {
    projects, loading, showModal: projectData.showModal, dispatchProject, setLoading,
  };

  return (
    <>
      <ProjectsContext.Provider value={proData}>
        {props.children}
      </ProjectsContext.Provider>
    </>
  );
};

export default ProjectsDataHolder;
