import React from 'react';

const Phrase = (props) => {
  const { phrase } = props;

  const formatPhrase = () => {
    try {
      let newPhrase = (phrase || '').replace(/\[/g, '<span class="tag is-info">').trim();
      newPhrase = newPhrase.replace(/]/g, '</span>').trim();
      newPhrase = newPhrase.replace(/ *\([^)]*\) */g, '').trim();
      return newPhrase;
    } catch (e) {
      return (phrase || '');
    }
  };

  const renderPhrase = () => {
    if (typeof phrase === 'undefined' || phrase === null || phrase.length <= 0) return (<></>);
    try {
      if (phrase.includes('<script')) return (<></>);
      return (<p dangerouslySetInnerHTML={{ __html: formatPhrase() }}/>);
    } catch (e) {
      return (<></>);
    }
  };

  return renderPhrase();
};

export default Phrase;
