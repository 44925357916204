import React, { useContext, useState } from 'react';
import { Form, Modal } from 'react-bulma-components';
import SnackbarContext from '../../../contexts/SnackbarContext';
import ProjectsContext from '../../../contexts/ProjectsContext';
import '../modal.css';
import '../modal-small.css';

const NewProjectModal = () => {
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const { showModal, dispatchProject, loading, setLoading} = useContext(ProjectsContext);
  const [project, setProject] = useState('');

  const dispatchHide = () => {
    setLoading(false);
    setProject('');
    dispatchProject({ type: 'hideModal' });
  };

  const saveProject = () => {
    if (project.length <= 0) {
      dispatchSnackbar({ style: 'error', text: 'No se ha escrito el nombre del proyecto' });
      return;
    }
    setLoading(true);
    dispatchProject({ type: 'create', payload: project });
  };

  const cleanProjectName = name => (name || '').replace(/[^a-zA-Z0-9-]+/g, '');

  if (!showModal) return (<></>);

  return (<>
    <Modal show={showModal} closeOnBlur={!loading} showClose={!loading}
           onClose={dispatchHide} className={'small'}>
      <Modal.Content>
        <Modal.Card>
          <Modal.Card.Head showClose={false}>
            <Modal.Card.Title>Crear nuevo proyecto</Modal.Card.Title>
          </Modal.Card.Head>
          <Modal.Card.Body>
            <Form.Field>
              <Form.Label>Nombre del Proyecto</Form.Label>
              <Form.Control>
                <Form.Input
                  onChange={e => setProject(cleanProjectName(e.target.value))}
                  name="project"
                  type="text"
                  placeholder="Proyecto"
                  value={project}
                  autoComplete="off"/>
              </Form.Control>
            </Form.Field>
          </Modal.Card.Body>
          <Modal.Card.Foot style={{ alignItems: 'right', justifyContent: 'right' }}
                           className={'has-text-right'}>
            <div className={'buttons'}>
              <button className={'button'} onClick={dispatchHide} disabled={loading}>
                Cancelar
              </button>
              <button className={`button is-info ${loading ? 'is-loading' : ''}`}
                      onClick={saveProject}>
                Guardar
              </button>
            </div>
          </Modal.Card.Foot>
        </Modal.Card>
      </Modal.Content>
    </Modal>
  </>);
};

export default NewProjectModal;
