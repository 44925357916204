import React, { useContext } from 'react';
import AudioContext from '../../contexts/AudioContext';

const RecordingResults = () => {
  const { voice, intervals, intents, stt } = useContext(AudioContext);
  const { prediction = {} } = intervals;
  const sttText = stt ? stt.text || '' : '';

  const formatIntervals = (item) => {
    if (typeof item === 'undefined' || item === null) return (<></>);
    return (item.intervals || []).map((it, i) => (
      <span key={i} className={'tag is-link'}>{it[0]} ~ {it[1]}</span>
    ));
  };

  return (
    <>
      <div className={'table-container'}>
        <table className={'table is-bordered is-narrow is-fullwidth recording-results'}>
          <tbody>
            {Object.keys(voice).length > 0
              ? (
                <tr>
                  <td><b>Voz Reconocida</b></td>
                  <td colSpan={2}>
                <span className={`mdi mdi-36px ${voice.voice
                  ? 'mdi-check-circle'
                  : 'mdi-alert-octagon'}`}/>
                  </td>
                </tr>) : (<></>)}
            {Object.keys(stt).length > 0
              ? (<tr>
                <td><b>Transcripción a Texto</b></td>
                {/* eslint-disable-next-line no-nested-ternary */}
                <td colSpan={2}>{sttText ? sttText.length > 0 ? sttText : 'N/A' : 'N/A'}</td>
              </tr>) : (<></>)}
            {Object.keys(intents).length > 0
              ? (<tr>
                <td><b>Resultado Predict-One</b></td>
                {/* eslint-disable-next-line no-nested-ternary */}
                <td colSpan={2}>{intents ? intents.intent || 'N/A' : 'N/A'}</td>
              </tr>) : (<></>)}
            {Object.keys(intervals).length > 0
              ? (
                <>
                  <tr>
                    <td rowSpan={4}><b>Intervalos<br/>(en ms)</b></td>
                    <td>Si</td>
                    <td>
                      <div className={'tags'}>
                        {formatIntervals(prediction[1])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>No</td>
                    <td>
                      <div className={'tags'}>
                        {formatIntervals(prediction[2])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Silencio</td>
                    <td>
                      <div className={'tags'}>
                        {formatIntervals(prediction[0])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Otro</td>
                    <td>
                      <div className={'tags'}>
                        {formatIntervals(prediction[3])}
                      </div>
                    </td>
                  </tr>
                </>) : (<></>)}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RecordingResults;
