import React, { useContext, useMemo, useState } from 'react';
import { Modal } from 'react-bulma-components';
import UpdatePhraseContext from '../../../contexts/UpdatePhraseContext';
import PhraseCommons from '../../phrase/common';
import '../modal.css';
import '../modal-small.css';

const DeletionModal = () => {
  const { phrase, showDeleteModal: showModal, dispatchPhrase: dispatch } = useContext(
    UpdatePhraseContext,
  );

  const [loading, setLoading] = useState(false);

  useMemo(() => {
    setLoading(false);
    // We need the following dependencies to update the loading state when any of these change
    // eslint-disable-next-line
  }, [phrase, showModal]);

  const dispatchHide = () => {
    if (loading) return;
    dispatch({ type: 'hideModal' });
  };

  const dispatchPhrase = () => {
    setLoading(true);
    dispatch({ type: 'confirmPhrase' });
  };

  if (showModal) {
    return (<>
      <Modal show={showModal} closeOnBlur={!loading} showClose={!loading}
             onClose={dispatchHide} className={'small'}>
        <Modal.Content>
          <Modal.Card>
            <Modal.Card.Head showClose={false}>
              <Modal.Card.Title>Eliminar Frase</Modal.Card.Title>
            </Modal.Card.Head>
            <Modal.Card.Body>
              ¿Estás seguro de querer eliminar la frase?
              <br/><br/>
              "{PhraseCommons.formatPhrase(phrase)}"
            </Modal.Card.Body>
            <Modal.Card.Foot style={{ alignItems: 'right', justifyContent: 'right' }}
                             className={'has-text-right'}>
              <div className={'buttons'}>
                <button className={'button'} onClick={dispatchHide} disabled={loading}>
                  No
                </button>
                <button className={`button is-danger ${loading ? 'is-loading' : ''}`}
                        onClick={dispatchPhrase}>
                  Si
                </button>
              </div>
            </Modal.Card.Foot>
          </Modal.Card>
        </Modal.Content>
      </Modal>
    </>);
  }
  return (<></>);
};

export default DeletionModal;
