import React from 'react';
import { Container, Hero } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import ProjectsToolbar from '../toolbar/ProjectsToolbar';

const NotFound = () => (
  <>
    <ProjectsToolbar/>
    <Hero className={'is-fullheight'}>
      <Hero.Body>
        <Container>
          <h2>Error 404</h2>
          <h5>No encontramos el sitio que solicitaste</h5>
          <br/><br/>
          <Link to={'/'}>
            <button className={'button is-info'}>Página Principal</button>
          </Link>
        </Container>
      </Hero.Body>
    </Hero>
  </>
);

export default NotFound;
