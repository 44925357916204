import React, { useContext, useState } from 'react';
import SnackbarContext from '../../contexts/SnackbarContext';
import MDIconText from '../mdi/MDIconText';
import api from '../../apis/TrainingApi';

const ToolbarTrainButton = () => {
  const { dispatchSnackbar } = useContext(SnackbarContext);

  const [canTrain, allowTrain] = useState(true);

  const triggerTrain = () => {
    allowTrain(false);
    dispatchSnackbar({ text: 'Entrenamiento iniciado!' });
    api.trainRasa()
      .then(() => {
        dispatchSnackbar({ text: 'Entrenamiento finalizado correctamente!' });
        allowTrain(true)
      })
      .catch((err) => {
        allowTrain(true);
        const message = err ? (err.message || '').toString() : '';
        if (message.length > 0 && !message.includes('503')) {
          dispatchSnackbar({ style: 'error', text: err.message });
        }
      });
  };

  const getButtonContent = () => {
    if (canTrain) return (<MDIconText iconName={'brain'} text={'Entrenar Bot'}/>);
    return (<>Entrenar Bot</>);
  };

  return (
    <>
      <button className={`button ${canTrain ? '' : 'is-loading'}`}
              onClick={triggerTrain} type={'button'}
              disabled={!canTrain}>
        {getButtonContent()}
      </button>
    </>
  );
};

export default ToolbarTrainButton;
