import { api, escalateError, getResponseData } from './index';

export default class PhrasesApi {
  static savePhrase(project, intent, phrase) {
    if (typeof project === 'undefined' || project === null || project.length <= 0) {
      throw new Error('No se ha seleccionado ningun proyecto');
    }
    if (typeof intent === 'undefined' || intent === null || intent.length <= 0) {
      throw new Error('No se ha seleccionado un intent');
    }
    if (typeof phrase === 'undefined' || phrase === null || phrase.length <= 0) {
      throw new Error('No se ha escrito ninguna frase');
    }
    const newProject = { name: project, intents: [{ name: intent, phrases: [phrase] }] };
    return api.post('/api/projects', newProject)
      .then(getResponseData)
      .catch(escalateError);
  }

  static deletePhrase(project, intent, phrase) {
    if (typeof project === 'undefined' || project === null || project.length <= 0) {
      throw new Error('No se ha seleccionado ningun proyecto');
    }
    if (typeof intent === 'undefined' || intent === null || intent.length <= 0) {
      throw new Error('No se ha seleccionado un intent');
    }
    if (typeof phrase === 'undefined' || phrase === null || phrase.length <= 0) {
      throw new Error('No se ha escrito ninguna frase');
    }
    return api.delete(`/api/projects/${project}/intents/${intent}/phrases`, { data: { phrase } })
      .then(getResponseData)
      .catch(escalateError);
  }
}
