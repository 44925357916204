import React, { useReducer } from 'react';
import EntitiesContext from '../contexts/EntitiesContext';

const entitiesReducer = (data, action) => {
  switch (action.type) {
    case 'add':
      // eslint-disable-next-line no-case-declarations
      const newItemIndex = data.findIndex(it => it.words === action.payload.words);
      if (newItemIndex < 0) data.push(action.payload);
      return Array.from(new Set(data));
    case 'remove':
      return data.filter(it => it.words !== action.payload);
    case 'update': {
      const itemIndex = data.findIndex(it => it.words === action.payload.words);
      if (itemIndex < 0) {
        data.push(action.payload);
      } else {
        // eslint-disable-next-line no-param-reassign
        data[itemIndex] = action.payload;
      }
      return Array.from(new Set(data));
    }
    case 'clear':
      return [];
    default:
      return data;
  }
};

const EntitiesDataHolder = (props) => {
  const [entities, dispatchEntities] = useReducer(entitiesReducer, []);

  const data = { entities, dispatchEntities };

  return (
    <>
      <EntitiesContext.Provider value={data}>
        {props.children}
      </EntitiesContext.Provider>
    </>
  );
};

export default EntitiesDataHolder;
