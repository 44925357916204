import React, { useContext, useMemo, useState } from 'react';
import { Form } from 'react-bulma-components';
import MDIcon from '../../mdi/MDIcon';
import EntitiesContext from '../../../contexts/EntitiesContext';

const EntityRow = (props) => {
  const { dispatchEntities } = useContext(EntitiesContext);

  const [entityKey, setEntityKey] = useState(props.id || '');

  useMemo(() => {
    dispatchEntities({ type: 'update', payload: { key: entityKey, words: props.words } });
    // eslint-disable-next-line
  }, [entityKey]);

  const deleteEntity = () => {
    dispatchEntities({ type: 'remove', payload: props.words });
  };

  const cleanPropsId = props.id || '';

  const cleanKey = key => (key || '').replace(/[^a-zA-Z0-9-]+/g, '');

  return (
    <>
      <tr>
        <td>
          <Form.Field>
            <Form.Control>
              <Form.Input onChange={e => setEntityKey(cleanKey(e.target.value))}
                          value={cleanPropsId.length > 0 ? cleanPropsId : entityKey}
                          autoComplete="off"/>
            </Form.Control>
          </Form.Field>
        </td>
        <td>{props.words}</td>
        <td>
          <button className={'button is-danger'} onClick={deleteEntity}>
            <MDIcon iconName={'delete'}/>
          </button>
        </td>
      </tr>
    </>
  );
};

export default EntityRow;
