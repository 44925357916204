/* eslint-disable object-curly-newline */
import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import SnackbarContext from '../contexts/SnackbarContext';
import IntentsContext from '../contexts/IntentsContext';
import api from '../apis/IntentsApi';

const selectedIntentReducer = (data, action) => {
  switch (action.type) {
    case 'selectIntent':
      return { ...data, selectedIntent: action.payload, triggerUpdate: false };
    case 'triggerUpdate':
      return { ...data, triggerUpdate: true };
    case 'stopUpdate':
      return { ...data, triggerUpdate: false };
    case 'setForPending':
      return { ...data, pendingSite: action.payload || false };
    default:
      return data;
  }
};

const IntentsDataHolder = (props) => {
  const { dispatchSnackbar } = useContext(SnackbarContext);

  const [intents, setIntents] = useState([]);
  const [intentPhrases, setIntentPhrases] = useState([]);
  const [loading, setLoading] = useState(false);

  const [intentReducer, dispatchIntent] = useReducer(selectedIntentReducer,
    { selectedIntent: '', triggerUpdate: false, pendingSite: false });

  const loadAllIntents = () => {
    api.getAllIntents(props.project)
      .then(data => setIntents((data.intents || []).map(it => it.name)))
      .catch((err) => {
        setIntents([]);
        dispatchSnackbar({ style: 'error', text: err.message });
      });
  };

  useEffect(() => {
    loadAllIntents();
    // eslint-disable-next-line
  }, []);

  const loadIntentPhrases = () => {
    setLoading(true);
    try {
      api.getIntentPhrases(props.project, intentReducer.selectedIntent)
        .then((data) => {
          setLoading(false);
          const intent = data.intent || { phrases: [] };
          try {
            setIntentPhrases((intent.phrases || []).reverse());
          } catch (e) {
            setIntentPhrases([]);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIntentPhrases([]);
          dispatchSnackbar({ style: 'error', text: err.message });
        });
    } catch (e) {
    }
  };

  useMemo(() => {
    loadIntentPhrases();
    // eslint-disable-next-line
  }, [intentReducer.selectedIntent]);

  useMemo(() => {
    if (intentReducer.triggerUpdate) {
      loadAllIntents();
      loadIntentPhrases();
      if (!intentReducer.pendingSite) {
        intentReducer.triggerUpdate = false;
      }
    }
    // eslint-disable-next-line
  }, [intentReducer.triggerUpdate]);

  const data = {
    intents: intents || [],
    intentPhrases: intentPhrases || [],
    selectedIntent: intentReducer.selectedIntent || '',
    dispatchIntent,
    triggerUpdate: intentReducer.triggerUpdate,
    loading,
    pendingSite: intentReducer.pendingSite || false,
  };

  return (
    <IntentsContext.Provider value={data}>
      {props.children}
    </IntentsContext.Provider>
  );
};

export default IntentsDataHolder;
