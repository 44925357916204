import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bulma-components/';
import MDIconText from '../mdi/MDIconText';
import IntentsContext from '../../contexts/IntentsContext';
import UpdatePhraseContext from '../../contexts/UpdatePhraseContext';
import PhraseCommons from './common';
import './buttons.css';

const PhraseButtons = (props) => {
  const { selectedIntent } = useContext(IntentsContext);
  const { dispatchPhrase } = useContext(UpdatePhraseContext);

  const dispatchSelectPhrase = (phrase) => {
    const expectedIntent = props.expectedIntent || '';
    const formattedPhrase = PhraseCommons.formatPhrase(phrase || '');
    dispatchPhrase({
      type: 'toUpdate',
      payload: formattedPhrase,
      intent: expectedIntent.length > 0 ? expectedIntent : selectedIntent || '',
      pending: expectedIntent.length > 0,
    });
  };

  const dispatchDeletePhrase = (phrase) => {
    dispatchPhrase({
      type: 'toDelete',
      payload: phrase || '',
      intent: selectedIntent || '',
    });
  };

  const testPhraseUrl = () => {
    const first = `/project/${props.project}/test`;
    const second = `?text=${encodeURI(PhraseCommons.formatPhrase(props.phrase))}`;
    return `${first}${second}`;
  };

  const renderDeleteBtn = () => {
    if (!props.canDelete) return (<></>);
    return (
      <>
        <Link className={'button is-info'} to={testPhraseUrl()}>
          <MDIconText iconName={'flask'} text={'Probar'}/>
        </Link>
        <Button className={'is-danger'} onClick={() => dispatchDeletePhrase(props.phrase)}>
          <MDIconText iconName={'delete'} text={'Eliminar'}/>
        </Button>
      </>
    );
  };

  return (
    <div className={'buttons is-right'}>
      <Button onClick={() => dispatchSelectPhrase(props.phrase)}>
        <MDIconText iconName={'pencil'} text={'Editar'}/>
      </Button>
      {renderDeleteBtn()}
    </div>
  );
};

export default PhraseButtons;
