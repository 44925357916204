import axios from 'axios';

const apiURL = process.env.REACT_APP_API_URL || 'http://localhost:8082';

export const api = axios.create({
  baseURL: apiURL,
  headers: { 'Content-Type': 'application/json' },
});

export const getApiURL = () => apiURL;
export const getResponseData = resp => resp.data;
export const escalateError = (err) => {
  let errorData = null;
  try {
    errorData = err.response.data;
  } catch (e) {
  }
  const defErrorMsg = err ? err.message || err.toString() : 'Error inesperado';
  if (typeof errorData !== 'undefined' && errorData !== null) {
    throw new Error(errorData.error || defErrorMsg);
  }
  throw new Error(defErrorMsg);
};

export default api;
