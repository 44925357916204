import React, { useContext, useEffect, useMemo, useState, } from 'react';
import { Table } from 'react-bulma-components';
import IntentsContext from '../../contexts/IntentsContext';
import PendingContext from '../../contexts/PendingContext';
import FilterField from '../filter/FilterField';
import PhraseRow from '../phrase/PhraseRow';
import Spinner from '../spinner/Spinner';
import './tables.css';

const PendingPhrases = () => {
  const { dispatchIntent } = useContext(IntentsContext);
  const { loading, phrases } = useContext(PendingContext);

  const [filteredPhrases, setFilteredPhrases] = useState(phrases || []);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    dispatchIntent({ type: 'setForPending', payload: true });
    // eslint-disable-next-line
  }, []);

  useMemo(() => {
    // Trigger update when loading state changes
    // eslint-disable-next-line
  }, [loading]);

  const updateFilter = () => {
    setFilteredPhrases( 
      filterText.length > 0 ? 
      phrases.length > 0 ? 
      phrases.filter(it => it.toLowerCase().includes(filterText.toLowerCase())) : phrases || [] 
      : phrases || [],
    );
  };

  useMemo(() => {
    updateFilter();
    // eslint-disable-next-line
  }, [phrases, filterText]);

  if (loading) {
    return (
      <>
        <Spinner text={'Cargando…'} show={loading}/>
      </>
    );
  }

  if (phrases && phrases.length > 0) {
    return (
      <>
        <Table className={`is-bordered is-striped ${(phrases || []).length <= 0 ? 'is-hidden'
          : ''}`} size={'narrow'}>
          <thead>
            <tr>
              <th>Frases pendientes</th>
              <td>
                <FilterField onChange={e => setFilterText(e.target.value)} text={filterText}/>
              </td>
            </tr>
          </thead>
          <tbody>
            {(filteredPhrases || []).map((it, i) => <PhraseRow key={i}
                                                               phrase={it}
                                                               canDelete={false}/>)}
          </tbody>
        </Table>
      </>
    );
  }
  return (
    <>
      <div className={'has-text-centered'}>
        <p>No hay frases pendientes por entrenar</p>
      </div>
    </>
  );
};

export default PendingPhrases;
