import React from 'react';
import { Container, Hero } from 'react-bulma-components';
import ParserContent from '../parser/ParserContent';
import Toolbar from '../toolbar/Toolbar';

const Testing = ({ project }) => {
  const getText = () => {
    let text = '';
    try {
      const pars = new URLSearchParams(window.location.search);
      text = pars.get('text') || '';
    } catch (e) {
      text = '';
    }
    window.history.pushState({}, document.title, `/project/${project}/test`);
    return text;
  };

  return (
    <>
      <Toolbar project={project}/>
      <Hero className={'is-fullheight'}>
        <Hero.Body>
          <Container>
            <ParserContent text={getText()} project={project}/>
          </Container>
        </Hero.Body>
      </Hero>
    </>
  );
};

export default Testing;
