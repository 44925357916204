// eslint-disable-next-line object-curly-newline
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Columns, Form } from 'react-bulma-components';
import SnackbarContext from '../../contexts/SnackbarContext';
import ParseContext from '../../contexts/ParseContext';
import Recorder from '../audio/Recorder';
import ParserResults from './ParserResults';
import api from '../../apis/ParseApi';

const ParserContent = (props) => {
  const { dispatchSnackbar } = useContext(SnackbarContext);

  const [phrase, setPhrase] = useState(props.text || '');
  const [parsedData, setParsedData] = useState(null);
  const [parse, setToParse] = useState(false);
  const [loading, setLoading] = useState(false);

  const parsePhrase = async () => {
    setLoading(true);
    setToParse(false);
    const response = await api.parse(props.project, phrase)
      .catch((err) => {
        dispatchSnackbar({ style: 'error', text: err.message });
        return {};
      });
    setParsedData(response || {});
    setLoading(false);
  };

  const validateValue = () => {
    if(phrase !== '') {
      setToParse(true);
    } else {
      dispatchSnackbar({ style: 'error', text: `No se ha seleccionado ninguna frase`});
    }
  }

  useEffect(() => {
    if (phrase.length > 0) {
      // noinspection JSIgnoredPromiseFromCall
      parsePhrase();
    }
    // eslint-disable-next-line
  }, []);

  useMemo(async () => {
    if (parse) await parsePhrase();
    // eslint-disable-next-line
  }, [phrase, parse]);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') setToParse(true);
  };

  const data = { parsedData, loading };

  return (
    <>
      <Columns className={'is-8'}>
        <Columns.Column>
          <h5><b>Pruebas de Texto</b></h5>
          <br/>
          <Form.Field className={'is-grouped'}>
            <Form.Control className={'is-expanded'}>
              <Form.Input
                value={phrase}
                onChange={e => setPhrase(e.target.value)}
                onKeyDown={onKeyDown}
                placeholder={'Hola mundo'}/>
            </Form.Control>
            <Form.Control>
              <Button className={'is-info'} onClick={() => validateValue()}>Probar</Button>
            </Form.Control>
          </Form.Field>
          <br/>
          <ParseContext.Provider value={data}>
            <ParserResults/>
          </ParseContext.Provider>
        </Columns.Column>
        <Columns.Column size={1}/>
        <Recorder/>
      </Columns>
    </>
  );
};

export default ParserContent;
