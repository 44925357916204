import { api, escalateError, getResponseData } from './index';

export default class ProjectsApi {
  static getProjects() {
    return api.get('/api/projects')
      .then(getResponseData)
      .catch(escalateError);
  }

  static saveProject(project) {
    return api.post('/api/projects', { name: project })
      .then(getResponseData)
      .catch(escalateError);
  }
}
