import React, { useState } from 'react';
import { Navbar } from 'react-bulma-components';
import { Link } from 'react-router-dom';
import ToolbarTrainButton from './ToolbarTrainButton';
import './toolbar.css';

const ProjectsToolbar = () => {
  const [open, setOpen] = useState(false);
  const openMenu = () => setOpen(!open);

  return (
    <>
      <nav className={'navbar has-shadow is-fixed-top'}>
        <Navbar.Brand>
          <Link to='/' className={'navbar-item unactivable'}><b>Proyectos</b></Link>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a role={'button'}
             className={`navbar-burger ${open ? 'is-active' : ''}`}
             href={'#'}
             aria-label={'menu'}
             aria-expanded={false}
             onClick={openMenu}>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
            <span aria-hidden="true"/>
          </a>
        </Navbar.Brand>
        <div className={`navbar-menu ${open ? 'is-active' : ''}`}>
          <Navbar.Container position="end">
            <Navbar.Item>
              <ToolbarTrainButton/>
            </Navbar.Item>
          </Navbar.Container>
        </div>
      </nav>
    </>
  );
};

export default ProjectsToolbar;
