import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Columns, Form } from 'react-bulma-components';
import AudioContext from '../../contexts/AudioContext';
import SnackbarContext from '../../contexts/SnackbarContext';
import AudiosApi from '../../apis/AudiosApi';
import RecordingResults from './RecordingResults';
import './recorder.css';

const audioMimeType = 'audio/x-gsm';
const validMimeTypes = ['audio/wav', 'audio/x-wav', 'audio/gsm', 'audio/x-gsm'];
const sttEngines = ['Google', 'Servex'];

const Recorder = () => {
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const audioInputRef = useRef();
  const audioElRef = useRef();

  const [hasPermission, setHasPermission] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [canRequestPermission, setCanRequestPermission] = useState(Boolean(navigator.mediaDevices));
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [shouldStop, setShouldStop] = useState(false);
  const [stopped, setStopped] = useState(false);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState('');
  const [engine, setEngine] = useState(sttEngines[0]);
  const [processing, setProcessing] = useState(false);
  const [file, setFile] = useState(null);

  const [voiceResult, setVoiceResult] = useState({});
  const [intervalsResult, setIntervalsResult] = useState({});
  const [intentsResult, setIntentsResult] = useState({});
  const [sttResult, setSttResult] = useState({});

  const handleSuccess = (stream, startRecording = false) => {
    const recordedChunks = [];
    const newMediaRecorder = new MediaRecorder(stream);

    newMediaRecorder.addEventListener('dataavailable', (e) => {
      if (e.data.size > 0) recordedChunks.push(e.data);
    });

    newMediaRecorder.addEventListener('stop', () => {
      const newAudioBlob = new Blob(recordedChunks, { type: audioMimeType });
      setAudioBlob(newAudioBlob);
      setAudioUrl((window.URL || window.webkitURL).createObjectURL(newAudioBlob));
    });

    setMediaRecorder(newMediaRecorder);
    setRecording(startRecording);
    if (startRecording) setStopped(false);
  };

  const requestPermission = (startRecording = false) => {
    if (recording) {
      setShouldStop(true);
      return;
    }
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then((stream) => {
        setPermissionDenied(false);
        setHasPermission(true);
        handleSuccess(stream, startRecording);
      })
      .catch((err) => {
        console.error(err);
        setPermissionDenied(true);
      });
  };

  const checkPermission = () => {
    try {
      navigator.permissions.query({ name: 'microphone' })
        .then((result) => {
          if (result.state === 'granted') {
            setPermissionDenied(false);
            setHasPermission(true);
          } else if (result.state === 'prompt') {
            setPermissionDenied(false);
          } else if (result.state === 'denied') {
            setPermissionDenied(true);
          }
        })
        .catch(() => {
          setPermissionDenied(true);
          setCanRequestPermission(false);
        });
    } catch (e) {
      setPermissionDenied(false);
      setCanRequestPermission(true);
    }
  };

  const dispatchErrorSnackbar = (err, app = '') => {
    if (typeof err === 'undefined' || err === null) return;
    let errorText = '';
    if (typeof app === 'string' && app.length > 0) errorText = `Error from ${app}:\n`;
    dispatchSnackbar({
      text: `${errorText}${err ? err.message || err.toString() : 'Error inesperado'}`,
      style: 'error',
    });
  };

  const processAudio = async (audioPath) => {
    try {
      await AudiosApi.checkVoice(audioPath, engine)
        .then((data) => {
          if (data.success) {
            setVoiceResult(data);
          } else {
            dispatchErrorSnackbar(data.error, 'Voice');
          }
        })
        .catch((err) => {
          setVoiceResult({});
          setProcessing(false);
          dispatchErrorSnackbar(err, 'Voice');
        });
      await AudiosApi.getIntervals(audioPath, engine)
        .then((data) => {
          if (data.success) {
            setIntervalsResult(data);
          } else {
            dispatchErrorSnackbar(data.error, 'Prediction');
          }
        })
        .catch((err) => {
          setIntervalsResult({});
          setProcessing(false);
          dispatchErrorSnackbar(err, 'Prediction');
        });
      await AudiosApi.getIntent(audioPath, engine)
        .then((data) => {
          if (data.success) {
            setIntentsResult(data);
          } else {
            dispatchErrorSnackbar(data.error, 'Intent');
          }
        })
        .catch((err) => {
          setIntentsResult({});
          setProcessing(false);
          dispatchErrorSnackbar(err, 'Intent');
        });
      await AudiosApi.stt(audioPath, engine)
        .then((data) => {
          if (data.success) {
            setSttResult(data);
          } else {
            dispatchErrorSnackbar(data.error, 'STT');
          }
        })
        .catch((err) => {
          setSttResult({});
          setProcessing(false);
          dispatchErrorSnackbar(err, 'STT');
        });
      setProcessing(false);
    } catch (e) {
      setVoiceResult({});
      setIntervalsResult({});
      setIntentsResult({});
      setSttResult({});
      setProcessing(false);
      dispatchErrorSnackbar(e);
    }
  };

  const parseAudio = () => {
    try {
      setProcessing(true);
      setVoiceResult({});
      setIntervalsResult({});
      setIntentsResult({});
      setSttResult({});
      AudiosApi.saveAudio(file || audioBlob)
        .then((data) => {
          if (data.error) {
            setProcessing(false);
            dispatchSnackbar({ text: data.error || 'Error inesperado', style: 'error' });
          } else {
            processAudio(data.filePath);
          }
        })
        .catch((err) => {
          setProcessing(false);
          dispatchErrorSnackbar(err);
        });
    } catch (e) {
      setProcessing(false);
      setVoiceResult({});
      setIntervalsResult({});
      setIntentsResult({});
      setSttResult({});
      dispatchErrorSnackbar(e);
    }
  };

  const clearAudioInput = () => {
    try {
      audioInputRef.current.dataTransfer.files = null;
    } catch (e) {
    }
    try {
      audioInputRef.current.files = null;
    } catch (e) {
    }
    try {
      audioInputRef.current.value = null;
    } catch (e) {
    }
    setFile(null);
  };

  const clearRecording = (fromCancel = false) => {
    if (fromCancel) clearAudioInput();
    setAudioBlob(null);
    setAudioUrl(null);
    setStopped(true);
    setShouldStop(false);
    setRecording(false);
  };

  const filesToArray = (files) => {
    if (!files) return [];
    const array = [];
    for (let i = 0; i < files.length; i += 1) {
      array.push(files.item(i));
    }
    return array;
  };

  const onFilesAdded = (e) => {
    e.preventDefault();
    const files = filesToArray(e.dataTransfer ? e.dataTransfer.files : e.target.files);
    if (typeof files === 'undefined' || files === null || files.length <= 0) return;
    try {
      const addedFile = files[0] || null;
      if (addedFile) {
        const fileType = addedFile.type || '';
        const isValid = validMimeTypes.includes(fileType) || (addedFile.name || '').endsWith('gsm');
        if (isValid) {
          setFile(addedFile);
        } else {
          dispatchErrorSnackbar('El formato del audio debe ser wav o gsm');
        }
      } else {
        dispatchErrorSnackbar('Error inesperado');
      }
    } catch (err) {
      dispatchErrorSnackbar(err ? err.message || err.toString() : 'Error inesperado');
    }
  };

  const onDragOver = e => e.preventDefault();

  useMemo(() => {
    if (file) {
      clearRecording();
      try {
        setAudioUrl((window.URL || window.webkitURL).createObjectURL(file));
      } catch (e) {
      }
    }
    // eslint-disable-next-line
  }, [file]);

  useMemo(() => {
    if (audioUrl) {
      try {
        audioElRef.current.pause();
        audioElRef.current.load();
        audioElRef.current.currentTime = 0;
      } catch (e) {
      }
      if (audioBlob) setFile(null);
    }
  }, [audioBlob, audioUrl]);

  useMemo(() => {
    if (shouldStop === true && stopped === false) {
      if (mediaRecorder) mediaRecorder.stop();
      setStopped(true);
      setShouldStop(false);
      setRecording(false);
    }
  }, [shouldStop, stopped, mediaRecorder]);

  useMemo(() => {
    if (recording && mediaRecorder) mediaRecorder.start();
  }, [recording, mediaRecorder]);

  useEffect(() => {
    navigator.getUserMedia = navigator.getUserMedia
      || navigator.webkitGetUserMedia
      || navigator.mozGetUserMedia
      || navigator.msGetUserMedia
      || navigator.oGetUserMedia;
    checkPermission();
  }, []);

  const getRecordButtonText = () => {
    if (recording) return '⬛ Detener';
    if (shouldStop) return '';
    return '● Grabar';
  };

  const renderSendButton = () => {
    if (stopped && !recording && ((audioBlob && audioUrl) || file)) {
      return (
        <>
          <audio controls={true} ref={audioElRef}>
            <source src={audioUrl}/>
          </audio>
          <br/><br/>
          <p>Selecciona el Motor de Inferencia de Voz a Texto</p>
          <Form.Field className={'has-addons has-addons-centered engine-selector'}>
            <Form.Control>
              <Form.Select value={engine} onChange={e => setEngine(e.target.value)}
                           disabled={processing}>
                {sttEngines.map(
                  (it, i) => (<option value={it.toLowerCase()} key={i}>{it}</option>),
                )}
              </Form.Select>
            </Form.Control>
            <Form.Control>
              <Button className={`is-info ${processing ? 'is-loading' : ''}`}
                      onClick={parseAudio} disabled={processing}>Enviar</Button>
            </Form.Control>
          </Form.Field>
        </>
      );
    }
    return (<></>);
  };

  const renderRecordButton = () => {
    if (canRequestPermission) {
      if (permissionDenied) {
        return (
          <>
            <p className={'is-danger'}>Permiso denegado</p>
            <br/>
          </>
        );
      }
      if (hasPermission) {
        return (
          <>
            <div className={'buttons is-centered'}>
              {!file
                ? (<Button className={`is-info ${shouldStop ? 'is-loading' : ''}`}
                           onClick={() => { requestPermission(true); }}
                           disabled={shouldStop}>
                  {getRecordButtonText()}
                </Button>) : (<></>)}
              {stopped && !recording && audioBlob && audioUrl
                ? (<Button className={'is-danger'} onClick={() => clearRecording(true)}>
                  <span className={'mdi mdi-delete'}/>&nbsp;Cancelar
                </Button>)
                : (<></>)}
            </div>
          </>
        );
      }
      return (
        <>
          <Button className={'is-info'} onClick={requestPermission}>Conceder Permiso</Button>
          <br/><br/>
        </>
      );
    }
    return (
      <>
        <p className={'is-danger'}>Grabación no soportada</p>
        <br/>
      </>
    );
  };

  const audioData = {
    voice: voiceResult,
    intervals: intervalsResult,
    intents: intentsResult,
    stt: sttResult,
  };

  return (
    <Columns.Column>
      <h5><b>Pruebas de Voz</b></h5>
      <br/>
      <div className={'has-text-centered'}>
        {renderRecordButton()}
        {!audioBlob && !recording
          ? (<>
            <fieldset className={'audio-upload'} disabled={audioBlob}>
              <div className={`file has-name is-centered is-primary ${audioBlob ? 'is-disabled'
                : ''}`} onDragOver={onDragOver} onDrop={onFilesAdded}>
                <label className={'file-label'}>
                  <input className={'file-input'} ref={audioInputRef}
                         type={'file'} name={'audio'}
                         disabled={(typeof audioBlob !== 'undefined' && audioBlob !== null)}
                         onChange={onFilesAdded}/>
                  <span className={`file-cta ${audioUrl || audioBlob ? 'is-disabled' : ''}`}>
                  <span className={'file-icon mdi mdi-file-upload'}/>
                  <span className={'file-label'}>Seleccionar archivo</span>
                  </span>
                  <span className={'file-name'}>{file ? file.name || '…' : '…'}</span>
                </label>
              </div>
            </fieldset>
            <br/>
            {stopped && !recording && audioUrl && file
              ? (<>
                <Button className={'is-danger'} onClick={() => clearRecording(true)}>
                  <span className={'mdi mdi-delete'}/>&nbsp;Cancelar
                </Button>
                <br/><br/>
              </>)
              : (<></>)}
          </>) : (<></>)
        }
        {renderSendButton()}
      </div>
      <br/>
      <AudioContext.Provider value={audioData}>
        <RecordingResults/>
      </AudioContext.Provider>
    </Columns.Column>
  );
};

export default Recorder;
