import React, { useReducer } from 'react';
import { Route, Switch } from 'react-router-dom';
import SnackbarContext from './contexts/SnackbarContext';
import ProjectsDataHolder from './data/ProjectsDataHolder';
import Snackbar from './components/snackbar/Snackbar';
import Projects from './components/projects/Projects';
import SiteTitle from './components/SiteTitle';
import NotFound from './components/sites/NotFound';
import SelectedProjectSite from './components/projects/SelectedProjectSite';

const snackbarReducer = (data, action) => {
  const actionType = action.type || 'show';
  switch (actionType) {
    case 'show':
      return {
        ...data,
        show: true,
        text: action.payload || action.text || '',
        style: action.style || '',
        duration: action.duration,
      };
    case 'hide':
      return { ...data, show: false };
    case 'removeText':
      return { ...data, show: false, text: '' };
    default:
      return data;
  }
};

const App = () => {
  const [snackbarData, dispatchSnackbar] = useReducer(snackbarReducer,
    {
      show: false,
      text: '',
      style: '',
    });

  const data = {
    show: snackbarData.show,
    text: snackbarData.text,
    style: snackbarData.style,
    duration: snackbarData.duration,
    dispatchSnackbar,
  };

  return (
    <>
      <SnackbarContext.Provider value={data}>
        <ProjectsDataHolder>
          <Switch>
            <Route exact path={'/'}>
              <SiteTitle title={'Proyectos ~ Entrenador RASA'}/>
              <Projects/>
            </Route>
            <Route path={'/project/:project'} component={SelectedProjectSite}/>
            <Route>
              <SiteTitle title={'Error 404 ~ Entrenador RASA'}/>
              <NotFound/>
            </Route>
          </Switch>
        </ProjectsDataHolder>
        <Snackbar/>
      </SnackbarContext.Provider>
    </>
  );
};

export default App;
