import React, { useContext } from 'react';
import { Columns, Container, Hero } from 'react-bulma-components';
import ProjectsContext from '../../contexts/ProjectsContext';
import ProjectsToolbar from '../toolbar/ProjectsToolbar';
import Spinner from '../spinner/Spinner';
import Project from './Project';
import './projects.css';
import NewProjectFab from '../fab/NewProjectFab';
import NewProjectModal from '../modals/project/NewProjectModal';

const Projects = () => {
  const { loading, projects } = useContext(ProjectsContext);

  const renderProjects = () => {
    if (projects.length <= 0) {
      return (<><p>No se encontraron proyectos</p></>);
    }
    return (
      <>
        <Columns>
          {(projects || []).map((it, i) => (
            <Columns.Column key={i} size={2}>
              <Project name={it.name}/>
            </Columns.Column>
          ))}
        </Columns>
      </>
    );
  };

  return (
    <>
      <ProjectsToolbar/>
      <Hero className={'is-fullheight'}>
        <Hero.Body>
          <Container>
            {loading
              ? <Spinner show={true} text={'Cargando…'}/>
              : renderProjects()}
          </Container>
        </Hero.Body>
      </Hero>
      <NewProjectFab/>
      <NewProjectModal/>
    </>
  );
};

export default Projects;
