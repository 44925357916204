import React from 'react';
import './spinner.css';

const Spinner = (props) => {
  const text = props.text || '';
  const show = props.show || false;

  if (show) {
    return (
      <div className={`spinner ${props.className || ''}`}>
        <p className={`has-text-centered ${text.length > 0 ? '' : 'is-hidden'}`}>{text}</p>
        <br/>
        <div className={'loading-spinner-container'}>
          <svg className={'loading-spinner'} viewBox="-24 -24 48 48">
            <circle className="path" cx="0" cy="0" r="20" fill="none" strokeWidth="4"/>
          </svg>
        </div>
      </div>
    );
  }
  return (<></>);
};

export default Spinner;
