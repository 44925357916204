import React from 'react';
import { Form } from 'react-bulma-components';

const FilterField = props => (
  <>
    <Form.Field>
      <Form.Control>
        <Form.Input onChange={props.onChange} value={props.text} placeholder={'Buscar…'}/>
      </Form.Control>
    </Form.Field>
  </>
);

export default FilterField;
