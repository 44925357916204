import React from 'react';
import { Container, Hero } from 'react-bulma-components';
import Toolbar from '../toolbar/Toolbar';
import IntentPhrases from '../content/IntentPhrases';
import DeletionModal from '../modals/deletion/DeletionModal';

const Main = ({ project }) => (
  <>
    <Toolbar showIntents={true} project={project}/>
    <Hero className={'is-fullheight'}>
      <Hero.Body>
        <Container>
          <IntentPhrases project={project}/>
          <DeletionModal/>
        </Container>
      </Hero.Body>
    </Hero>
  </>
);

export default Main;
